import { atom } from 'jotai'
import { io } from 'socket.io-client'
import { Socket } from 'socket.io-client/debug'
import * as Sentry from '@sentry/react'

const backendApiUrl = import.meta.env.BACKEND_SERVICE

/*
  Local stream atoms
*/
interface MediaDevice {
  facing: 'front' | 'back' | string
  kind: 'videoinput' | 'audioinput' | string
}

// interface RTCStats {
//   [key: string]: unknown
//   id: string
//   timestamp: number
//   type: string
// }

// interface RTCOutboundRtpStreamStats extends RTCStats {
//   bytesSent?: number
//   type: 'outbound-rtp'
// }

// interface RTCInboundRtpStreamStats extends RTCStats {
//   bytesReceived?: number
//   type: 'inbound-rtp'
// }

const frontCameraAtom = atom(true)
const mediaConstraintsAtom = atom((get) => ({
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
  },
  video: {
    facingMode: get(frontCameraAtom) ? 'user' : 'environment',
    frameRate: { ideal: 30, max: 60 },
    width: { ideal: 1280, max: 1920 },
    height: { ideal: 720, max: 1080 },
    aspectRatio: 16 / 9,
  },
}))

const localStreamAtom = atom<MediaStream | null>(null)
const attemptedFetchingStreamAtom = atom(false)
const missingPermissionsAtom = atom(false)
const hasMultipleCamerasAtom = atom(false)

const releaseLocalStreamAtom = atom(null, async (get, set) => {
  const stream = get(localStreamAtom)
  if (stream) {
    stream.getTracks().forEach((track) => track.stop())
    set(localStreamAtom, null)
  }
})

const startLocalStreamAtom = atom(
  (get) => get(localStreamAtom),
  async (get, set) => {
    await set(releaseLocalStreamAtom)
    try {
      set(attemptedFetchingStreamAtom, true)
      const constraints = get(mediaConstraintsAtom)
      const streamBuffer =
        await navigator.mediaDevices.getUserMedia(constraints)

      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoInputDevices = devices.filter(
        (device) => device.kind === 'videoinput',
      )
      if (videoInputDevices.length > 1) {
        console.log('Multiple cameras found')
        set(hasMultipleCamerasAtom, true)
      }

      set(localStreamAtom, streamBuffer)
    } catch (e) {
      console.error('Error starting local stream:', e)
      set(missingPermissionsAtom, true)
    }
  },
)

const switchCameraAtom = atom(
  (get) => get(frontCameraAtom),
  async (get, set) => {
    await set(releaseLocalStreamAtom)

    const frontCamera = get(frontCameraAtom)
    const socket = get(socketAtom)
    set(frontCameraAtom, !frontCamera)

    const pc = get(peerConnectionAtom)
    const micEnabled = get(micEnabledAtom)
    try {
      set(attemptedFetchingStreamAtom, true)
      const constraints = get(mediaConstraintsAtom)
      const streamBuffer =
        await navigator.mediaDevices.getUserMedia(constraints)

      // if mic is disabled, mute the audio track
      const audioTrack = streamBuffer.getAudioTracks()[0]
      if (audioTrack) {
        audioTrack.enabled = micEnabled
      }

      if (pc && socket) {
        const senders = pc.getSenders()
        streamBuffer.getTracks().forEach((track) => {
          const sender = senders.find((s) => s.track?.kind === track.kind)
          if (sender) {
            sender.replaceTrack(track)
          } else {
            pc.addTrack(track, streamBuffer)
          }
        })

        set(sendOfferAtom)
      }

      set(localStreamAtom, streamBuffer)
    } catch (e) {
      console.error('Error starting local stream:', e)
      set(missingPermissionsAtom, true)
    }
  },
)

const videoEnabledAtom = atom(true)
const toggleVideoAtom = atom(
  (get) => get(videoEnabledAtom),
  async (get, set) => {
    const stream = get(localStreamAtom)
    const pc = get(peerConnectionAtom)

    if (stream) {
      const videoTracks = stream.getVideoTracks()
      if (videoTracks.length > 0) {
        try {
          videoTracks[0].enabled = !videoTracks[0].enabled
          set(videoEnabledAtom, videoTracks[0].enabled)

          if (pc) {
            const senders = pc.getSenders()
            const videoSender = senders.find(
              (sender) => sender.track?.kind === 'video',
            )

            if (videoTracks[0].enabled) {
              // Video is being enabled
              if (videoSender) {
                await videoSender.replaceTrack(videoTracks[0])
              } else {
                pc.addTrack(videoTracks[0], stream)
              }
            } else {
              // Video is being disabled
              if (videoSender) pc.removeTrack(videoSender)
            }

            set(sendOfferAtom)
          }
        } catch (error) {
          Sentry.captureException(error)
          console.error('Error toggling video:', error)
        }
      }
    }
  },
)

const micEnabledAtom = atom(true)
const toggleMicAtom = atom(
  (get) => get(micEnabledAtom),
  async (get, set) => {
    const stream = get(localStreamAtom)
    if (stream) {
      const audioTracks = stream.getAudioTracks()
      if (audioTracks.length > 0) {
        try {
          audioTracks[0].enabled = !audioTracks[0].enabled
          set(micEnabledAtom, audioTracks[0].enabled)
        } catch (error) {
          Sentry.captureException(error)
          console.error('Error toggling microphone:', error)
        }
      }
    }
  },
)

// Send offer to peer, wait for answer
const sendOfferAtom = atom(null, async (get) => {
  const pc = get(peerConnectionAtom)
  const socket = get(socketAtom)
  const joined = get(joinedAtom)

  console.log('Sending offer !!!')

  if (pc && socket && joined) {
    const offer = await pc.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    })
    await pc.setLocalDescription(offer)
    socket.emit('offer', { offer: pc.localDescription })
  }
})

// Restart ICE and resend offer if joined
const restartIceAtom = atom(null, async (get) => {
  const pc = get(peerConnectionAtom)
  const socket = get(socketAtom)
  const joined = get(joinedAtom)

  if (pc && socket && joined) {
    console.log('Restarting ICE')
    pc.restartIce()

    const offer = await pc.createOffer({
      iceRestart: true,
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    })
    await pc.setLocalDescription(offer)
    socket.emit('offer', { offer: pc.localDescription })
  }
})

/*
  Remote stream and ice candidates
*/
const remoteStreamAtom = atom<MediaStream | null>(null)
const pendingIceCandidatesAtom = atom<RTCIceCandidate[]>([])

const addPendingIceCandidateAtom = atom(
  (get) => get(pendingIceCandidatesAtom),
  async (get, set) => {
    const pc = get(peerConnectionAtom)
    if (pc) {
      const pendingRemoteCandidates = get(pendingIceCandidatesAtom)

      console.log(
        `Adding ${pendingRemoteCandidates.length} pending ICE candidates`,
      )

      // Loop through any pending ice candidates and add them to peer connection
      for (const candidate of pendingRemoteCandidates) {
        try {
          await pc.addIceCandidate(candidate)
        } catch (error) {
          console.error('Error adding pending ICE candidate:', error)
          Sentry.captureException(error)
        }
      }

      // Clear pending ice candidates
      set(pendingIceCandidatesAtom, [])
    }
  },
)

/*
  Socket
*/
const socketAtom = atom<Socket | null>(null)
const handshakeAtom = atom(false)
const setSocketAtom = atom(
  (get) => get(socketAtom),
  async (get, set, code: string) => {
    console.log('Setting socket with code:', code)

    const socket = io(backendApiUrl, {
      autoConnect: true,
      path: '/ws',
      query: {
        code,
      },
      reconnectionDelayMax: 10000,
      transports: ['websocket'],
      withCredentials: false,
    })

    socket.on('connect', () => {
      console.log('Connected to signalling server')
    })

    socket.on('connect_error', (err) => {
      console.error("Cound't connect to signalling server", err)
    })

    socket.on('handshake', (data) => {
      if (data.status == 'successful') {
        set(handshakeAtom, true)
      }
    })

    /*
      Answer an incoming call
    */
    socket.on('answer', async (data) => {
      const pc = get(peerConnectionAtom)
      if (pc) {
        console.log(
          '[Answer]: PeerConnection state before setting remote desc:',
          pc.signalingState,
        )

        try {
          // Set remote description for peer
          await pc.setRemoteDescription(data.answer)

          // Add any pending ice candidates to peer connection
          set(addPendingIceCandidateAtom)
        } catch {
          console.error('Error setting remote description')
        }
      }
    })

    /*
      Handle ICE candidates
    */
    socket.on('icecandidate', (data) => {
      console.log('Received ICE candidate:', data)
      try {
        const pc = get(peerConnectionAtom)
        const iceCandidate = new RTCIceCandidate(data.candidate)

        if (pc && pc.remoteDescription) {
          console.log('Remote description is set. Adding ICE candidate')
          pc.addIceCandidate(iceCandidate).catch((error) => {
            console.error('Error adding ICE candidate:', error)
            Sentry.captureException(error)
          })
        } else {
          console.log('Queueing ICE candidate')
          set(pendingIceCandidatesAtom, (prev) => [...prev, iceCandidate])
        }
      } catch (e) {
        console.error('Error handling ICE candidate:', e)
        Sentry.captureException(e)
      }
    })

    /*
      Reply to an offer
    */
    socket.on('offer', async (data) => {
      const pc = get(peerConnectionAtom)
      if (pc) {
        console.log(
          '[Offer]: PeerConnection state before setting remote desc:',
          pc.signalingState,
        )

        if (data.offer) {
          try {
            const desc = new RTCSessionDescription(data.offer)
            await pc.setRemoteDescription(desc)
            // await setConsistentDescription(pc, data.offer)

            const answer = await pc.createAnswer()
            // await setConsistentDescription(pc, answer)
            await pc.setLocalDescription(answer)

            // Send answer back to the peer
            socket.emit('answer', {
              answer: pc.localDescription,
              id: data.id,
            })

            // Add any pending ice candidates to peer connection
            set(addPendingIceCandidateAtom)
          } catch (e) {
            console.error('Error handling offer:', e)
            Sentry.captureException(e)
          }
        }
      }
    })

    set(socketAtom, socket)
  },
)

const releaseSocketAtom = atom(null, async (get, set) => {
  const socket = get(socketAtom)
  if (socket) {
    console.log('Disconnecting socket')
    socket.disconnect()
    set(socketAtom, null)
  }
})

const joinedAtom = atom(false)
const setJoinedAtom = atom(
  (get) => get(joinedAtom),
  async (get, set) => {
    const pc = get(peerConnectionAtom)
    const socket = get(socketAtom)

    if (pc && socket) {
      socket.emit('ready')
      set(joinedAtom, true)
    }
  },
)

/*
  WebRTC Peer connection
*/
const bytesSentAtom = atom<number>(0)
const bytesReceivedAtom = atom<number>(0)
// const monitoringIntervalAtom = atom<NodeJS.Timeout | null>(null)
const peerConnectionAtom = atom<RTCPeerConnection | null>(null)
const setPeerConnectionAtom = atom(
  (get) => get(peerConnectionAtom),
  async (get, set, iceServers: RTCIceServer[]) => {
    const localStream = get(localStreamAtom)
    const socket = get(socketAtom)

    if (localStream && socket) {
      const pc = new RTCPeerConnection({
        iceServers,
        iceTransportPolicy: 'all',
      })

      // Add tracks in a consistent order: audio first, then video
      const audioTrack = localStream.getAudioTracks()[0]
      const videoTrack = localStream.getVideoTracks()[0]

      if (audioTrack) {
        pc.addTrack(audioTrack, localStream)
      }
      if (videoTrack) {
        pc.addTrack(videoTrack, localStream)
      }

      // these are the potential candidates to create and maintain p2p connection
      pc.onicecandidate = (event) => {
        if (!event.candidate) return
        console.log(
          'New ice candidate available. SENDING ice candidate to peer',
          event.candidate,
        )

        // Send new icecandidate to peer
        socket.emit('icecandidate', { candidate: event.candidate })

        // Log if the candidate is a STUN or TURN server
        if (event.candidate.type === 'srflx') {
          console.log('STUN server:', event.candidate.address)
        } else if (event.candidate.type === 'relay') {
          console.log('TURN server:', event.candidate.address)
        }
      }

      pc.oniceconnectionstatechange = () => {
        const state = pc.iceConnectionState
        console.log(`ICE connection state changed to: ${state}`)

        switch (state) {
          case 'new':
            console.log(
              'ICE agent is gathering addresses or is waiting for remote candidates',
            )
            break
          case 'checking':
            console.log(
              'ICE agent has received remote candidates and is checking pairs',
            )
            break
          case 'connected':
            console.log(
              'ICE agent has found a usable connection, but is still checking other pairs',
            )
            break
          case 'completed':
            console.log(
              'ICE agent has finished gathering and checking all candidates',
            )
            break
          case 'failed':
            console.error(
              'ICE agent has checked all candidates but failed to find a connection',
            )
            try {
              set(restartIceAtom)
              console.log('Attempting to restart ICE connection')
            } catch (error) {
              console.error('Failed to restart ICE:', error)
            }
            break
          case 'disconnected':
            set(restartIceAtom)
            console.warn('ICE connection has been disrupted')
            break
          case 'closed':
            console.log(
              'ICE agent has shut down and is no longer handling requests',
            )
            break
          default:
            console.warn(`Unknown ICE connection state: ${state}`)
        }

        // Log additional information about ICE candidates
        const localCandidates =
          pc.localDescription?.sdp.match(/a=candidate:.+/g) || []
        const remoteCandidates =
          pc.remoteDescription?.sdp.match(/a=candidate:.+/g) || []
        console.log(`Local ICE candidates: ${localCandidates.length}`)
        console.log(`Remote ICE candidates: ${remoteCandidates.length}`)

        // Log current selected candidate pair if available
        pc.getStats()
          .then((stats) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            stats.forEach((report: any) => {
              if (report.type === 'candidate-pair' && report.selected) {
                console.log('Selected candidate pair:', report)
              }
            })
          })
          .catch((error) => console.error('Error getting stats:', error))
      }

      pc.ontrack = (event) => {
        console.log('Received remote stream')
        if (event.streams && event.streams[0]) {
          set(remoteStreamAtom, event.streams[0])

          // const interval = get(monitoringIntervalAtom)
          // if (interval) clearInterval(interval)

          // const monitoringInterval = setInterval(() => {
          //   pc.getStats()
          //     .then((stats: RTCStatsReport) => {
          //       let totalBytesSent = 0
          //       let totalBytesReceived = 0

          //       stats.forEach((report: RTCStats) => {
          //         if (report.type === 'outbound-rtp') {
          //           const outboundReport = report as RTCOutboundRtpStreamStats
          //           totalBytesSent += outboundReport.bytesSent || 0
          //         } else if (report.type === 'inbound-rtp') {
          //           const inboundReport = report as RTCInboundRtpStreamStats
          //           totalBytesReceived += inboundReport.bytesReceived || 0
          //         }
          //       })

          //       if (totalBytesSent === 0 && totalBytesReceived === 0) {
          //         console.warn('No bytes sent or received')
          //       }

          //       set(bytesSentAtom, totalBytesSent)
          //       set(bytesReceivedAtom, totalBytesReceived)
          //     })
          //     .catch(() => {})
          // }, 1000)
          // set(monitoringIntervalAtom, monitoringInterval)
        }
      }

      pc.onsignalingstatechange = () => {
        console.log('[Signaling state changed]: ', pc.signalingState)
        if (pc.signalingState === 'closed') {
          /* empty */
        }
      }

      pc.onnegotiationneeded = async (event) => {
        console.log('[Negotiation needed]: ', event)
        try {
          /* empty */
        } catch (error) {
          console.error('Error during renegotiation:', error)
          Sentry.captureException(error)
        }
      }

      pc.onicecandidateerror = async (event) => {
        console.error('ICE candidate error:', event)
        // new: The ICE agent is gathering addresses or waiting for remote candidates.
        // checking: The ICE agent has received remote candidates and is checking connections.
        // connected: The ICE agent has found a usable connection, but is still checking other candidates.
        // completed: The ICE agent has finished gathering and checking all candidates.
        // failed: The ICE agent has checked all candidates but was unable to find a connection.
        // disconnected: The ICE connection has been lost, but the agent is trying to reconnect.
        // closed: The ICE agent has shut down and is no longer responding to STUN requests.
        if (pc.iceConnectionState !== 'closed') {
          console.log('ICE agent has shut down. Restarting ICE agent')
          pc.restartIce()
        }
      }

      pc.onconnectionstatechange = () => {
        console.log(`[Connection State]: ${pc.connectionState}`)
        switch (pc.connectionState) {
          case 'new':
            break
          case 'connecting':
            break
          case 'connected':
            break
          case 'disconnected':
            set(restartIceAtom)
            break
          case 'closed':
            break
          case 'failed':
            // implement fallback or error handling logic here?
            break
        }
      }

      set(peerConnectionAtom, pc)
    }
  },
)

const releasePeerConnectionAtom = atom(null, async (get, set) => {
  const pc = get(peerConnectionAtom)
  if (pc) {
    console.log('Closing peer connection')
    pc.close()
    set(peerConnectionAtom, null)
  }
})

const releaseAtom = atom(null, async (_, set) => {
  set(frontCameraAtom, true)
  set(attemptedFetchingStreamAtom, false)
  set(missingPermissionsAtom, false)
  set(videoEnabledAtom, true)
  set(micEnabledAtom, true)
  set(joinedAtom, false)

  set(releaseLocalStreamAtom)
  set(releasePeerConnectionAtom)
  set(releaseSocketAtom)
  set(pendingIceCandidatesAtom, [])

  set(remoteStreamAtom, null)
  set(bytesSentAtom, 0)
  set(bytesReceivedAtom, 0)

  // const interval = get(monitoringIntervalAtom)
  // if (interval) clearInterval(interval)
})

export type { MediaDevice }
export {
  attemptedFetchingStreamAtom,
  missingPermissionsAtom,
  mediaConstraintsAtom,
  handshakeAtom,
  hasMultipleCamerasAtom,
  peerConnectionAtom,
  releaseAtom,
  releaseLocalStreamAtom,
  releasePeerConnectionAtom,
  releaseSocketAtom,
  localStreamAtom,
  remoteStreamAtom,
  restartIceAtom,
  setJoinedAtom,
  setPeerConnectionAtom,
  setSocketAtom,
  socketAtom,
  startLocalStreamAtom,
  switchCameraAtom,
  toggleMicAtom,
  toggleVideoAtom,
}
