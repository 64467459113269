import { ReactElement, useEffect, useRef } from 'react'
import { Video } from '../compositions/Video'
import styled from 'styled-components'
import {
  hasMultipleCamerasAtom,
  localStreamAtom,
  peerConnectionAtom,
  remoteStreamAtom,
  setJoinedAtom,
  socketAtom,
} from '../atoms'
import { useAtom } from 'jotai'
import { Navigate, useLocation } from 'react-router-dom'

const Remote = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Waiting = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`

const WaitingText = styled.h2`
  font-weight: bold;
  font-size: 20px;
`

const RemoteVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Call = (): ReactElement => {
  const location = useLocation()

  const videoRef = useRef<HTMLVideoElement | null>(null)

  const [socket] = useAtom(socketAtom)
  const [pc] = useAtom(peerConnectionAtom)
  const [localStream] = useAtom(localStreamAtom)
  const [remoteStream] = useAtom(remoteStreamAtom)
  const [hasMultipleCameras] = useAtom(hasMultipleCamerasAtom)
  const [joined, join] = useAtom(setJoinedAtom)

  useEffect(() => {
    if (remoteStream) {
      if (videoRef.current) {
        videoRef.current.srcObject = remoteStream
      }
    }
  }, [remoteStream])

  useEffect(() => {
    if (localStream && pc && !joined) {
      join()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('remoteStream', remoteStream, remoteStream?.getVideoTracks())

  if (!socket || !pc) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return (
    <div>
      {!remoteStream && (
        <Waiting>
          <WaitingText>
            The meeting will start when the patient joins the call.
          </WaitingText>
        </Waiting>
      )}
      <Remote>
        <RemoteVideo ref={videoRef} autoPlay playsInline />
      </Remote>
      {localStream && (
        <Video stream={localStream} hasMultipleCameras={hasMultipleCameras} />
      )}
    </div>
  )
}

export { Call }
