import { ReactElement, useRef, useEffect, useCallback } from 'react'
import MicToggle from '../assets/Mic.svg'
import VideoToggle from '../assets/Video-Toggle.svg'
import Switch from '../assets/Camera-Switch.svg'
import VideoOff from '../assets/VideoOff.svg'
import MicOff from '../assets/MicOff.svg'
import EndCall from '../assets/Call.svg'
import styled, { css } from 'styled-components'
import { useAtom } from 'jotai'
import {
  releaseAtom,
  setJoinedAtom,
  toggleMicAtom,
  toggleVideoAtom,
} from '../atoms'

const Wrap = styled.div<{ $joined?: boolean }>`
  width: 100%;

  ${(props) =>
    props.$joined &&
    css`
      position: absolute;
      bottom: 0;
      color: white;
      transform: translateY(-20px);
      z-index: 100;
    `};
`

const VideoWrap = styled.div<{ $joined?: boolean }>`
  display: flex;
  flex: 1;
  flex-flow: column;

  ${(props) =>
    props.$joined &&
    css`
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0 20px 0 0;
    `}
`

const VideoElement = styled.video<{ $joined?: boolean }>`
  max-width: 400px;

  ${(props) =>
    props.$joined &&
    css`
      max-height: 25vh;
      max-width: 200px;
    `}
`

const Controls = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 16px auto;
  width: 100%;
  padding: 0 20px;
  max-width: 500px;
`

type VideoProps = {
  stream: MediaStream
  hasMultipleCameras: boolean
}

const button = `text-black rounded-full w-[48px] h-[48px] flex justify-center items-center p-0`

const Video = ({ stream, hasMultipleCameras }: VideoProps): ReactElement => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const [videoEnabled, toggleVideo] = useAtom(toggleVideoAtom)
  const [micEnabled, toggleMic] = useAtom(toggleMicAtom)
  const [, release] = useAtom(releaseAtom)
  const [joined] = useAtom(setJoinedAtom)

  useEffect(() => {
    console.log('new stream on video')
    if (videoRef.current) {
      videoRef.current.srcObject = stream
    }
  }, [stream])

  const switchCamera = useCallback(() => {}, [])

  const endCall = useCallback(() => {
    release()
  }, [release])

  return (
    <Wrap $joined={joined}>
      <VideoWrap $joined={joined}>
        <VideoElement
          $joined={joined}
          ref={videoRef}
          autoPlay
          playsInline
          muted
        />
      </VideoWrap>
      <Controls>
        {joined && (
          <button onClick={endCall} className={`${button} bg-[#BF002F]`}>
            <img src={EndCall} alt="Video" width={32} />
          </button>
        )}

        <button
          onClick={toggleVideo}
          className={`${button} ${!videoEnabled ? `bg-gray-400` : `bg-white`}`}
        >
          {!videoEnabled ? (
            <img src={VideoOff} alt="Video" width={32} />
          ) : (
            <img src={VideoToggle} alt="Video" width={32} />
          )}
        </button>

        <button
          onClick={toggleMic}
          className={`${button} ${!micEnabled ? `bg-gray-400` : 'bg-white'}`}
        >
          {!micEnabled ? (
            <img src={MicOff} alt="Microphone" width={32} />
          ) : (
            <img src={MicToggle} alt="Microphone" width={32} />
          )}
        </button>

        {hasMultipleCameras && false && (
          <button onClick={switchCamera} className={`${button} bg-white`}>
            <img src={Switch} alt="Microphone" width={32} />
          </button>
        )}
      </Controls>
    </Wrap>
  )
}

export { Video }
