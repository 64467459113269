import { ReactElement, useCallback, useEffect } from 'react'
import { Video } from '../compositions/Video'
import styled from 'styled-components'
import { Button } from '../components/Button'
import VideoSvg from '../assets/Video.svg'
import {
  attemptedFetchingStreamAtom,
  hasMultipleCamerasAtom,
  missingPermissionsAtom,
  setPeerConnectionAtom,
  socketAtom,
  startLocalStreamAtom,
} from '../atoms'
import { useAtom } from 'jotai'
import { useNavigate, Navigate, useLocation } from 'react-router-dom'

const Wrap = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
  padding: 0 20px;

  @media (min-width: 768px) {
    max-width: 400px;
  }
`

const backendApiUrl = import.meta.env.BACKEND_SERVICE

const twilioConfig = await fetch(`${backendApiUrl}/twilio-config`, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'GET',
}).then((res) => res.json())

const Setup = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  const [socket] = useAtom(socketAtom)
  const [localStream, startLocalStream] = useAtom(startLocalStreamAtom)
  const [attemptedFetchingStream] = useAtom(attemptedFetchingStreamAtom)
  const [missingPermissions] = useAtom(missingPermissionsAtom)
  const [hasMultipleCameras] = useAtom(hasMultipleCamerasAtom)
  const [pc, createPeerConnection] = useAtom(setPeerConnectionAtom)

  const handleJoin = useCallback(() => {
    if (localStream && pc) {
      navigate('/call')
    }
  }, [localStream, navigate, pc])

  /*
    Bootstrap
  */
  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      await startLocalStream()
      await createPeerConnection(twilioConfig.iceServers)
    })()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!socket) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return (
    <Wrap>
      {attemptedFetchingStream && missingPermissions && (
        <h4>Sorry, we could not start the camera on your device</h4>
      )}
      {!attemptedFetchingStream && !missingPermissions && <h4>Setting up..</h4>}
      {localStream && (
        <>
          <Video stream={localStream} hasMultipleCameras={hasMultipleCameras} />
          <Button onClick={handleJoin}>
            <img src={VideoSvg} width={20} /> Join video call
          </Button>
        </>
      )}
    </Wrap>
  )
}

export { Setup }
