import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '../components/Button'
import VideoSvg from '../assets/Video.svg'
import { handshakeAtom, setSocketAtom } from '../atoms'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'

const Wrap = styled.div`
  width: 90%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 24px;
  align-items: center;
`

const Start = (): ReactElement => {
  const navigate = useNavigate()
  const [code, setCode] = useState<string>('')
  const [socket, setSocket] = useAtom(setSocketAtom)
  const [handshake] = useAtom(handshakeAtom)

  const handleSubmit = useCallback(() => {
    if (socket) {
      socket.disconnect()
    }

    if (code.trim().length > 0) {
      setSocket(code)
    }
  }, [code, setSocket, socket])

  const handleCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCode(event.target.value)
    },
    [],
  )

  useEffect(() => {
    if (socket?.connected && handshake) {
      console.log('Redirecting to setup')
      navigate('/setup')
    }
  }, [socket, handshake, navigate])

  return (
    <Wrap>
      <h3 className="text-2xl font-bold">Enter room code</h3>
      <StyledForm>
        <input
          type="text"
          onChange={handleCodeChange}
          name="appointmentCode"
          // defaultValue={`6850520`}
          className="appearance-none border rounded-full w-full py-2 px-4 text-white leading-tight focus:outline-none"
        />

        <Button onClick={handleSubmit}>
          <img src={VideoSvg} width={20} /> Join video call
        </Button>
      </StyledForm>
    </Wrap>
  )
}

export { Start }
