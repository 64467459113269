import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'

import { Start } from './screens/Start'
import { Setup } from './screens/Setup'
import { Call } from './screens/Call'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Start />,
      },
      {
        path: 'setup',
        element: <Setup />,
      },
      {
        path: 'call',
        element: <Call />,
      },
    ],
  },
])

const RouterRoot = () => {
  return <RouterProvider router={router} />
}

export default RouterRoot
